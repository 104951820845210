export enum BookingStatus {
  CANCELLED = "CANCELLED",
  CONFIRMED = "CONFIRMED",
  CREATED = "CREATED",
  REJECTED = "REJECTED",
  REQUESTED = "REQUESTED",
}

export enum PaymentStatus {
  NOT_YET_PAID = "NOT_YET_PAID",
  PAID = "PAID",
  REFUNDED = "REFUNDED",
}

export enum ReservationStatus {
  CANCELLED = "CANCELLED",
  NOT_YET_RESERVED = "NOT_YET_RESERVED",
  REJECTED = "REJECTED",
  RESERVED = "RESERVED",
}

export enum ItemStatus {
  INCLUDES_ITEM_SET = "INCLUDES_ITEM_SET",
  WITHOUT_ITEM_SET = "WITHOUT_ITEM_SET",
  MISSING_GIFTMALL_ID = "MISSING_GIFTMALL_ID",
  MISSING_TRACKING_NO = "MISSING_TRACKING_NO",
  SHIPPED = "SHIPPED",
}

export type Holiday = "祝日" | "祝前日";

export enum BookingSystem {
  tablecheck = "TableCheck",
  ebica = "Ebica",
  toreta = "トレタ",
  eareji = "エアレジ",
  resuraku = "レスラク",
  resuti = "レスてぃ",
  board = "レストランボード",
  reszaiko = "Reszaiko",
  dedicatedSystem = "専用システム",
  ikyu = "一体",
  other = "その他",
  unknown = "不明",
}

export interface Booking {
  id: string;
  status: BookingStatus;
  createdAt: string;
  confirmedAt: string;
  rejectedAt: string;
  cancelledAt: string;
  cancelledBy: string;
  cancellationReason: string;
  cancellationFee?: number;
  noShow: boolean;
  plan: Plan;
  reservationDatetime: string;
  email: string;
  familyName: string;
  givenName: string;
  familyNameFurigana: string;
  givenNameFurigana: string;
  phoneNumber: string;
  paymentStatus: PaymentStatus;
  paidAt: string;
  refundedAt: string;
  paymentAmount: string;
  paymentCurrency: string;
  paymentIntentId: string;
  paymentIntent?: { method: string };
  notes: string;
  productOrders?: ProductOrder[];
}

export interface ActivityReservation {
  id: string;
  status: ReservationStatus;
  datetime: string;
  specialRequests: string | null;
  reservedAt: Date;
  rejectedAt: Date;
  cancelledAt: Date;
  commissionRate: number;
  venuePaymentRate: number;
  activity: Activity;
  booking: Booking;
  items: {
    id: string;
    quantity: number;
    costsCurrencyCode: string;
    contractedPrice: number;
    priceType: Activity["priceTypes"][0];
  }[];
  plan: Plan;
  venue: Venue;
}

export interface VenueBasicInfo {
  id: string;
  name: string;
  nameFurigana?: string;
  description: string;
  phoneNumber: string;
  email: string;
  notificationEmails?: string;
  invoiceEmails?: string;
  location: {
    address: string;
    googleMapsUrl: string;
    googleMapsEmbedUrl: string;
    directions: string;
    nearestStations?: string[];
  };
  acceptedPaymentMethods: string;
  dressCode?: string;
  limitedEntryForChildren: string;
  wheelChairAccess?: string;
  parking?: string;
  remarks?: string;
  numberOfSeats?: string;
  businessHours?: string;
  holidays?: string;
  smokingAllowance?: string;
  babyChair?: string;
  kidChair?: string;
  breastFeedingRoom?: string;
  postcode?: string;
  ebicaStoreId?: number;
  internalRemarks: string;
  company?: {
    id: string;
    name: string;
  };
  bookingSystems: BookingSystem[];
  feeFreePeriod: FeeFreePeriod;
  activities: Activity[];
  itemCourse: string;
}

export type Venue = VenueBasicInfo & {
  photos?: {
    imageUrl: string;
  }[];
  schedule?: Schedule;
};

export type VenueBasicInfoInput =
  | Omit<VenueBasicInfo, "company">
  | { companyId: string };

export interface Tag {
  id: number;
  name: string;
  slug?: string;
  tagCategoryId: number;

  tagCategory: TagCategory;
}

export interface TagCategory {
  id: number;
  name: string;

  tags?: Tag[];
}

export interface Inclusions {
  category: string;
  items: InclusionsItems[];
}
export interface InclusionsItems {
  id: string;
  title: string;
  description: string;
  iconName: string;
}

export interface CoursePhoto {
  id: string;
  src: string;
  activityId: string;
  photoIndex: number;
}

export interface Activity {
  id?: string;
  name?: string;
  courseMenu?: string;
  coursePhotos?: CoursePhoto[];
  detailsForVenue?: string;
  venue?: Venue;
  questionsByVenue?: string[];
  priceTypes: {
    id: string;
    unitType: UnitType;
    name: string;
    amount: number;
    contractedPrice: number;
    currencyCode: string;
    minAttendees: number;
    maxAttendees: number;
  }[];
  seatOptions: {
    id: string;
    title: string;
    details: string;
    minAttendees: number;
    maxAttendees: number;
    active?: boolean;
    photos: { src: string }[];
    price?: number;
  }[];
  contract: {
    commissionRate?: number;
  };
  reservations?: ActivityReservation[];
}

export interface PlanDraft {
  id: string;
  publishedAt: string;
  plan: Plan;
}

export interface PlanFilter {
  tags?: Tag[];
}

export interface Plan {
  id: string;
  timezone: string;
  name: string;
  subtitle?: string;
  tags?: Tag[];
  photos: {
    imageUrl: string;
  }[];
  description: string;
  highlights: {
    index: number;
    title: string;
    details: string;
    exclusive: boolean;
    media?: {
      src: string;
      type: "photo" | "image" | "video";
    };
  }[];
  remarks?: string;
  cancellationPolicyDescription?: string;
  paymentMethodDescription?: string;
  bookingType?: BookingType;
  activities: Activity[];
  drafts: PlanDraft[];
  inclusions?: Inclusions[];
  planPaidItem?: PlanPaidItem[];
  mainVenue?: Venue;
  score?: number;
  manualScore?: number;
  derivedScore: number;
}

export interface Review {
  id: string;
  planId?: string;
  bookingId?: string;
  content: string;
  rating: number;
  show: boolean;
  reviewAt: string;
  createdAt: string;
  plan?: Plan;
  comment?: ReviewConciergeComment;
  images?: ReviewImage[];
}

export interface ReviewImage {
  id: string;
  imageUrl: string;
}

export interface ReviewConciergeComment {
  id: string;
  reviewId: string;
  conciergeId: string;
  comment: string;
}

export interface PlanFAQ {
  id: string;
  planId?: string;
  answer: string;
  question: string;
  priority: number;
  imageUrl?: string | null;
  plan?: Plan;
}

export interface PlanPaidItem {
  id: string;
  planId?: string;
  name: string;
  description: string;
  status: number;
  imageUrl?: string | null;
  plan?: Plan;
}

export interface PhotoImage {
  src: string;
  id?: string;
  category?: PhotoCategory;
}

export interface VenueImage {
  imageUrl: string;
  id?: string;
  category?: PhotoCategory;
}

export enum PhotoCategory {
  "内観/外観",
  "座席",
  "料理",
  "特典",
  "サービス",
}

export enum UnitType {
  PERSON = "名",
  SET = "セット",
}

export enum BookingType {
  INSTANT = "INSTANT",
  AVAILABILITIES = "AVAILABILITIES",
  REQUEST = "REQUEST",
}

export const PhotoCategories: PhotoCategory[] = Object.values(
  PhotoCategory
).filter((value): value is PhotoCategory => typeof value === "string");

export interface Location {
  id: string;
  createdAt: string;
  type: LocationType;
  nameEn: string;
  nameJa: string;
  parentId: string;
  slug: string;
  pageTitle: string;
  pageDescription: string;
  thumbnailImageUrl: string;
  imageUrl: string;
  videoUrl: string;
  isHomeContent: boolean;
  homePriority: number | null;

  parent: Location;
}

export enum LocationType {
  prefecture = "prefecture",
  city = "city",
  area = "area",
}

export interface LocationSpecial {
  index: number;
  title: string;
  linkText: string;
  imageUrl: string;
  url: string;
}

export interface LocationCollection {
  index: number;
  planCollectionId: string;
}

export interface Collection {
  id: string;
  name: string;
  slug: string;
}

export interface Partner {
  id: string;
  createdAt: string;
  lastUpdatedAt?: string;
  name: string;
  email: string;
  lastLoggedIn: string;
  userId: string | null;
  connectedToAllVenues: boolean | null;

  venues?: Venue[];
  user: BackofficeUser | null;
}

export enum BankAccountType {
  regular = "普通",
  current = "当座",
}

export interface BankAccount {
  id: string;
  createdAt: string;
  bank: string;
  holder: string;
  number: string;
  recipient: string;
  type: BankAccountType;
}

export interface Contract {
  id: string;
  companyId: string;
  url: string;
  name: string;
}

export interface Company {
  id: string;
  name: string;
  email: string;
  createdAt: string;
  contracts: Contract[];
  bankAccount?: BankAccount;
  venues: Venue[];
  partners: Partner[];
  sendToCompanyEmail: boolean;
}

export interface Announcement {
  id: string;
  createdAt: string;
  updatedAt?: string;
  synopsis: string;
  content: string;
}

export interface BackofficeUser {
  id: string;
  username: string;
  password: string;
  usersEditable: boolean;
  lastUpdatedAt?: Date | null;
  lastLoggedInAt?: Date | null;
  createdAt: Date;
  partner?: Partner | null;
  backofficeUserRoles: BackofficeUserRole[];
}

export interface BackofficeUserRole {
  id: string;
  userId: string;
  roleId: string;
  createdAt: Date;
}

export interface PlanTemplate {
  id: string;
  planId: string;
  templateId: string;
  howToGive: string;
  whenToGive: string;
  template: Template;
  cards: PlanTemplateCard[];
  highlightDefaultFlg: boolean;
}

export interface Template {
  id: string;
  name: string;
  displayName: string;
  overrideDisplayName: string;
  giftmallUrl: string;
  description?: string;
  withPaperbag: boolean;
  commissionRate: number;
  defaultHowToGive: string;
  defaultWhenToGive: string;
  undeliveryDates: Date[]; // Add this line
}

export interface TemplateImage {
  id: string;
  templateId: string;
  imageUrl: string;
  index: number;
}

export enum FrameType {
  "STAMP",
  "TEXT",
  "IMAGE",
  "DROPDOWN",
}

export enum PresetType {
  "hankaku",
  "zenkaku",
  "all",
}

export interface Frame {
  id: string;
  templateId: string;
  name?: string;
  overrideName?: string;
  type: FrameType;
  allowMultipleLines: boolean;
  index: number;
  skippable: boolean;
  frameFonts?: FrameFont[];
  frameStamps?: FrameStamp[];
}

export interface FrameFont {
  id: string;
  frameId: string;
  fontId: string;
  length: number;
  preset: PresetType;
  font: Font;
}

export interface FrameStamp {
  id: string;
  frameId: string;
  stampId: string;
  stamp: Stamp;
}

export interface Font {
  id: string;
  name: string;
  displayName: string;
  overrideDisplayName: string;
  imageUrl: string;
}

export interface Stamp {
  id: string;
  name: string;
  displayName: string;
  overrideDisplayName: string;
  svgUrl: string;
}

export interface Product {
  id: string;
  templateId: string;
  stock: number;
  minimumDeliveryDays: number;
  name: string;
  overrideName: string;
  sellingPrice: number;
  template: Template;
  created_at?: Date; // Add created_at field
}

export interface PlanTemplateCard {
  id: string;
  planTemplateId: string;
  title: string;
  description: string;
  imageUrl: string;
  index: number;
  fromHighlight?: boolean;
}

export interface ProductOrder {
  id: string;
  productId: string;
  bookingId: string;
  quantity: number;
  trackingNumber?: string;
  giftmallOrderId?: string;
  status: ProductOrderStatus;
  price?: number;
  product: Product;
  frames: ProductOrderFrame[];
  booking: Booking;
}

export enum ProductOrderStatus {
  "CREATED",
  "CONFIRMED",
  "SHIPPED",
  "FAILED",
}

export interface ProductOrderFrame {
  id: string;
  orderId: string;
  frameId: string;
  fontId: string;
  stampId: string;
  value: string;
  imageUrl?: string;
  stamp?: Stamp;
  font?: Font;
  frame: Frame;
}

export interface Concierge {
  id: string;
  createdAt: string;
  name: string;
  imageUrl: string;
}

export enum EditStatus {
  ADDED = "ADDED",
  REMOVED = "REMOVED",
  TOUCHED = "TOUCHED",
  UNTOUCHED = "UNTOUCHED",
}

export enum FieldName {
  NAME = "name",
  DESCRIPTION = "description",
  SUBTITLE = "subtitle",
  REMARKS = "remarks",
  COURSE = "course",
  COURSEMENU = "courseMenu",
  COURSEPHOTOS = "coursePhotos",
  HIGHLIGHTS = "highlights",
  FAQS = "faqs",
  PLANPAIDITEMS = "planPaidItems",
  MEALINCLUSIONS = "mealInclusions",
  GIFTINCLUSIONS = "giftInclusions",
  EXPERIENCEINCLUSIONS = "experienceInclusions",
  SEATINCLUSIONS = "seatInclusions",
  NOTINCLUDEDINCLUSIONS = "notIncludedInclusions",
}

export const PartnerPlanEditAction = {
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
} as const;

export interface PartnerPlanEdit {
  id: string;
  planId: string;
  partnerId: string;
  createdAt: string;
  verifiedAt: string | null;
  fieldName: FieldName;
  fieldId: string;
  newValue: any;
  oldValue: any;
  editStatus: EditStatus;
  action: typeof PartnerPlanEditAction;
}

export interface CollectionImage {
  id: string;
  createdAt: string;
  name: string;
  imageUrl: string;
}

export interface FeeFreePeriod {
  id: string;
  venueId: string;
  startDate: Date;
  endDate: Date;
  createdAt: Date;
}

export type LockableMoment = {
  moment: string;
  locked: boolean;
};

export type RecurrenceRule = {
  weekdays: string[];
  holidays?: string[];
  hours: number[];
  minutes: number[];
};

export type CutoffTime = {
  hours?: number;
};

export type BookablePeriod = {
  months?: number;
};

export type Schedule = {
  recurrenceRules: RecurrenceRule[];
  cutoffTime?: CutoffTime;
  bookablePeriod?: BookablePeriod;
  closedDates: LockableMoment[];
  closedHolidays: string[];
  closedTimeSlots: string[];
  openDates: string[];
  openTimeSlots: string[];
};

export type Pagination = {
  offset: number;
  limit: number;
};

export type RoleType = {
  id: string;
  name: string;
  createdAt: Date;
  accessPermissions?: AccessPermission[];
};

export type AccessPermission = {
  id?: string;
  name: string;
  path: string;
};
