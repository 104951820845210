import PageContent from "../../../components/PageContent";
import PageSection from "../../../components/PageSection";
import { CloudinaryImage } from "../../../components/Cloudinary";
import { Plan, Tag } from "../../../types";
import { Typography } from "@mui/material";
import Activity from "./Activity";
import { GenericButtonProps } from "../../../components/Button";

function removeDuplicateTags(tags: Tag[]) {
  return tags
    ?.filter((e) => e !== null && e.tagCategory !== null)
    .filter(
      (item, index, self) =>
        index === self.findIndex((t) => t?.name === item?.name)
    );
}

export default function PlanOverview(props: {
  title?: string;
  plan: Plan;
  publishButton: GenericButtonProps;
}) {
  const { plan } = props;

  return (
    <PageContent
      title={props.title || plan.name}
      buttons={[
        props.publishButton,
        {
          label: "Edit",
          severity: "default",
          href: `/plans/${plan.id}/edit`,
        },
      ]}
    >
      <PageSection lg={12}>
        <div style={{ width: "100%" }}>
          <Typography variant="h6">Data</Typography>
        </div>
        <p>
          Name: <br />
          {plan.name}
        </p>
        <p>
          Subtitle: <br />
          {plan.subtitle}
        </p>
        <p>
          Timezone: <br />
          {plan.timezone}
        </p>
        <p>
          Cancellation Policy Description: <br />
          {plan.cancellationPolicyDescription}
        </p>
        <p>
          Description: <br />
          {plan.description}
        </p>
        <p>
          Tags: <br />
          {removeDuplicateTags(plan.tags ?? [])
            .map((e) => `${e.tagCategory.name} : ${e.name}`)
            .join(", ")}
        </p>
        <p>
          Remarks: <br />
          {plan.remarks}
        </p>
      </PageSection>
      <PageSection lg={12}>
        <Typography variant="h6">Photos</Typography>
        {plan.photos?.map((p) => (
          <CloudinaryImage
            key={p.imageUrl}
            src={p.imageUrl}
            width={120}
            style={{ margin: 3 }}
            transformations={["w_120"]}
          />
        ))}
      </PageSection>
      <PageSection lg={12}>
        <Typography variant="h6">Highlights</Typography>
        {plan.highlights.map((h) => (
          <div>
            <p>
              {h.index} - Title: <br />
              {h.title}
            </p>
            <p>
              Details: <br />
              {h.details}
            </p>
            <p>
              {h.media ? (
                <CloudinaryImage
                  key={h.media?.src}
                  src={h.media?.src}
                  width={120}
                  style={{ margin: 3 }}
                  transformations={["w_120"]}
                />
              ) : (
                "IMAGE MISSING"
              )}
            </p>
          </div>
        ))}
      </PageSection>
      <Typography variant="h6">Activities</Typography>
      {/* {plan.activities.map((activity) => (
        <Activity activity={activity} />
      ))} */}
      <Activity activity={plan.activities} />
    </PageContent>
  );
}
