import {
  ApolloQueryResult,
  FetchMoreQueryOptions,
  gql,
  OperationVariables,
  useQuery,
} from "@apollo/client";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { useMemo } from "react";
import { FormatMoney } from "../../../../components/Money";
import { ProductOrder } from "../../../../types";

type UseGetItemSalesDataProps = {
  offset?: number;
  limit?: number;
  date: Date;
};
export default function useGetItemSalesData({
  offset,
  limit,
  date,
}: UseGetItemSalesDataProps) {
  const { data, ...rest } = useQuery<
    GetProductOrdersResponse,
    GetProductOrdersInput
  >(GET_PRODUCT_ORDERS, {
    variables: {
      offset,
      limit,
      from: format(startOfMonth(date!), "yyyy-MM-dd"),
      until: format(endOfMonth(date!), "yyyy-MM-dd"),
    },
  });

  const [productOrders, totalRows] = useMemo(
    () => [
      data?.productOrders.records ?? [],
      data?.productOrders.totalRecords ?? 0,
    ],
    [data]
  );

  const itemSalesData = useMemo(
    () =>
      productOrders.map((productOrder) => ({
        status: productOrder.status,
        datetime: format(
          new Date(productOrder.booking.reservationDatetime),
          "yyyy-MM-dd"
        ),
        bookingId: productOrder.booking.id,
        customerName: `${productOrder.booking.familyName} ${productOrder.booking.givenName}`,
        planName: productOrder.product.template.overrideDisplayName, //productOrder.booking.plan.name,
        price: FormatMoney({
          currencyCode: "JPY",
          amount: productOrder.product.sellingPrice,
        }),
        quantity: productOrder.quantity,
        payment: FormatMoney({
          currencyCode: "JPY",
          amount: productOrder.product.sellingPrice * productOrder.quantity,
        }),
        venueName: productOrder.booking.plan.activities[0].venue?.name,
      })),
    [productOrders]
  );

  return {
    itemSalesData,
    totalRows,
    ...rest,
  };
}

export const fieldTranslations = {
  status: "お祝いアイテム注文ステータス",
  datetime: "催行日",
  bookingId: "予約ID",
  customerName: "予約者名",
  planName: "アイテム名",
  price: "単価(税込)",
  quantity: "個数",
  payment: "支払金額",
  venueName: "店舗名",
} as const;

export type ItemSalesData = ReturnType<
  typeof useGetItemSalesData
>["itemSalesData"];
export type ItemSalesDataRow = ItemSalesData[number];
export type FetchMoreProducts = <
  TFetchData = GetProductOrdersResponse,
  TFetchVars extends OperationVariables = GetProductOrdersInput
>(
  fetchMoreOptions: FetchMoreQueryOptions<TFetchVars, TFetchData> & {
    updateQuery?:
      | ((
          previousQueryResult: GetProductOrdersResponse,
          options: {
            fetchMoreResult: TFetchData;
            variables: TFetchVars;
          }
        ) => GetProductOrdersResponse)
      | undefined;
  }
) => Promise<ApolloQueryResult<TFetchData>>;

export type GetProductOrdersInput = {
  offset?: number;
  limit?: number;
  from: string;
  until: string;
};
export type GetProductOrdersResponse = {
  productOrders: {
    records: ProductOrder[];
    totalRecords: number;
  };
};
export const GET_PRODUCT_ORDERS = gql`
  query GetProductOrders(
    $offset: Int
    $limit: Int
    $from: Date!
    $until: Date!
  ) {
    productOrders(offset: $offset, limit: $limit, from: $from, until: $until) {
      totalRecords
      records {
        id
        status
        quantity
        product {
          sellingPrice
          template {
            overrideDisplayName
          }
        }
        booking {
          id
          reservationDatetime
          familyName
          givenName
          plan {
            name
            activities {
              venue {
                name
              }
            }
          }
        }
      }
    }
  }
`;
