import { useRef } from "react";
import { debounce } from "lodash";
import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
} from "@mui/material";
import ColumnSelector from "./ColumnSelector";

const makeOptionalDebounce = (func: any | undefined, waitMs: number) => {
  return func ? debounce(func, waitMs) : undefined;
};

const DataTable = (props: DataTableProps) => {
  const debouncedOnSearch = useRef(
    makeOptionalDebounce(props.onSearch, props.searchDelay || 0)
  ).current;

  const finalSearch = props.searchDelay ? debouncedOnSearch : props.onSearch;

  const columnsToRenders =
    props.columns?.filter((column) =>
      props.showColumns ? props.showColumns?.includes(column.name) : true
    ) ?? [];

  return (
    <>
      <TableContainer component={Paper} style={props.style}>
        {!props.disableToolbar && (
          <Toolbar style={{ gap: "1rem", justifyContent: "end" }}>
            {[
              props.topBarLeadingExtras,
              props.onSearch ? (
                <TextField
                  variant="outlined"
                  size="small"
                  label="Search"
                  value={props.searchValue ? props.searchValue : undefined}
                  onChange={finalSearch}
                />
              ) : null,
              props.topBarExtras,
            ].filter(Boolean)}
            {!!props.showColumns && !!props.setShowColumns && (
              <ColumnSelector
                options={(props.columns ?? []).map((column) => column.name)}
                columns={props.showColumns}
                setColumns={props.setShowColumns}
              />
            )}
          </Toolbar>
        )}
        <Table size="small">
          <TableHead>
            <TableRow>
              {columnsToRenders.map((column, index) => (
                <TableCell
                  key={index}
                  style={{
                    fontWeight: "bold",
                    minWidth: column.width,
                    maxWidth: column.maxWidth,
                  }}
                >
                  {column.sortable ? (
                    <TableSortLabel
                      active={
                        props.order && props.order.field === column.sortField
                      }
                      direction={
                        props.order && props.order.direction
                          ? (props.order.direction.toLowerCase() as
                              | "asc"
                              | "desc")
                          : "asc"
                      }
                      onClick={() => {
                        if (
                          props.order &&
                          props.order.setField &&
                          props.order.setDirection &&
                          column.sortField
                        ) {
                          if (props.order.field === "") {
                            props.order.setField(column.sortField);
                            props.order.setDirection("ASC");
                          } else if (props.order.direction === "DESC")
                            props.order.setField("");
                          else if (props.order.direction === "ASC")
                            props.order.setDirection("DESC");
                        }
                      }}
                    >
                      {column.name}
                    </TableSortLabel>
                  ) : (
                    column.name
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.loading ? (
              <TableRow id={"loading-row"}>
                <TableCell colSpan={columnsToRenders.length}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            ) : props.rows && props.rows.length !== 0 ? (
              props.customRowsRenderer ? (
                props.customRowsRenderer(columnsToRenders, props.rows)
              ) : (
                <>
                  {props.rows.map((row, index) => (
                    <TableRow
                      id={row.id ? `${row.id}-row` : undefined}
                      key={index}
                      hover={true}
                    >
                      {columnsToRenders.map((column, index) => (
                        <TableCell
                          className={
                            column.name
                              ? `${column.name
                                  .replaceAll(" ", "-")
                                  .toLowerCase()}-cell`
                              : undefined
                          }
                          key={index}
                        >
                          {renderCell(row, column)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              )
            ) : (
              <TableRow>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={columnsToRenders.length}
                >
                  no data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {props.pagination ? (
          !props.loading ? (
            <TablePagination
              count={props.pagination.totalRows ?? 0}
              onPageChange={props.pagination.onChangePage}
              page={props.pagination.currentPage}
              rowsPerPage={props.pagination.pageSize}
              rowsPerPageOptions={[props.pagination.pageSize]}
              component="div"
            />
          ) : null
        ) : null}
      </TableContainer>
    </>
  );

  function renderCell(
    row: Record<string, unknown>,
    column: Column
  ): JSX.Element {
    if (column.render) return column.render(row);
    return <>{row[column.field]}</>;
  }
};

export default DataTable;

export type DataTableProps = {
  columns?: Column[];
  showColumns?: string[];
  setShowColumns?: React.Dispatch<React.SetStateAction<string[]>>;
  rows?: { [key: string]: any }[] | null;
  loading?: boolean;
  pagination?: {
    totalRows: number | null;
    pageSize: number;
    currentPage: number;
    onChangePage(event: object | null, page: number): void;
  };
  order?: {
    field: string;
    direction: "ASC" | "DESC";
    setField(field: string): void;
    setDirection(direction: "ASC" | "DESC"): void;
  };
  onSearch?(event: { target: { value: string } }): void;
  searchDelay?: number;
  disableToolbar?: boolean;
  searchValue?: string | null;
  topBarLeadingExtras?: JSX.Element;
  topBarExtras?: JSX.Element;
  customRowsRenderer?(
    columns?: Column[],
    rows?: Record<string, unknown>[]
  ): JSX.Element;
} & Pick<TableContainerProps, "style">;

interface BaseColumn {
  name: string;
  width?: string;
  maxWidth?: string;
  sortable?: boolean;
  sortField?: string;
}

interface FieldColumn extends BaseColumn {
  field: string;
  render?: never;
}

interface RenderColumn extends BaseColumn {
  field?: never;
  render(row: Record<string, any>): JSX.Element;
}

export type Column = FieldColumn | RenderColumn;
